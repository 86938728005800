<template>
    <card-component title="Link Package"
                    icon="link"
                    :is-scrollable="false">
        <form v-if="this.unused_packages.length > 0">
            <b-field label="Select Package" horizontal>
                <b-dropdown

                        :scrollable="true"
                        max-height="100"
                        v-model="package"
                        aria-role="list"
                >
                    <button class="button is-primary" type="button" slot="trigger">
                        <template>
                            <b-icon icon="eye"></b-icon>
                            <span>{{package.name}}</span>
                        </template>
                        <b-icon icon="menu-down"></b-icon>
                    </button>

                    <b-dropdown-item
                            v-for="(pack, index) in unused_packages"
                            :key="index"
                            :value="pack" aria-role="listitem">
                        <div class="media">
                            <b-icon class="media-left" icon="eye"></b-icon>
                            <div class="media-content">
                                <h3>{{pack.name}}</h3>
                            </div>
                        </div>
                    </b-dropdown-item>
                </b-dropdown>

            </b-field>

            <hr>
            <b-notification type="is-info" aria-close-label="Close notification">
                Drag the blue marker to the location of the package.
            </b-notification>

            <mapbox style="height: 250px" ref="linkpackagemap" :access-token="accessToken" :map-options="mapStyle"
                    :geolocate-control="{
                      show: false,
                      position: 'top-right',
                    }"
                    :nav-control="{
                      show: false,
                      position: 'top-right',
                    }"
                    @map-init="mapInitialized">

            </mapbox>

            <hr>

            <b-field label="Location" horizontal>
                <b-field>
                    <b-input v-model="package.long" placeholder="Longitude" name="long" required @input="updateLocation"/>
                </b-field>
                <b-field>
                    <b-input v-model="package.lat" placeholder="Latitude" name="lat" required @input="updateLocation" />
                </b-field>
            </b-field>
<!--            <b-field label="Site" horizontal>-->
<!--                <b-field>-->
<!--                    <b-input v-model="package.site_id" placeholder="1" name="site_id" required />-->
<!--                </b-field>-->
<!--            </b-field>-->

            <b-field horizontal >
                <b-field grouped position="is-right">

                    <div class="control">
                        <b-button  :disabled="!this.package.id" @click="submit" type="is-primary">Attach package to project</b-button>
                    </div>
                </b-field>
            </b-field>

        </form>
        <b-message v-else type="is-danger"> There are no packages available!</b-message>

    </card-component>
</template>

<script>
    import packages from "@/store/modules/packages.store"
    import CardComponent from "../CardComponent";

    import Mapbox from 'mapbox-gl-vue'
    import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
    import bbox from '@turf/bbox';

    export default {
        name: "PackageFormComponent",
        components: { CardComponent, Mapbox },
        props: {
            project: {
                default: null
            },
        },


        data() {
            return {
                package: {
                    id: null,
                    name: "",
                    site_id: null,
                    keycode: "",
                    long: 4.64,
                    lat: 50.44,
                    has_battery: false,
                },
                accessToken: 'pk.eyJ1IjoibHVja2FzZGMiLCJhIjoiY2sxdWR6d3B5MDkwOTNpcGFuNXh3dGhzNSJ9.nBYgv6LPBGFGDx56p8mRQQ',
                mapStyle: {
                    style: 'mapbox://styles/luckasdc/ckf29lnav0mm319qvg7u55h7l',
                    container: "linkpackagemap"
                },
                themap: {},
                marker: {},


            }
        },

        computed: {
            creating () {
                return this.id === null
            },
            unused_packages() {
                return this.$store.getters["packages/unusedPackages"]
            },


        },
        created() {
            if(!packages.isRegistered) {
                this.$store.registerModule('packages', packages)
                packages.isRegistered = true
            }
        },

        mounted() {
            this.$store.dispatch('packages/fetchPackages')
            this.$store.dispatch('mapbox/setStack', null)

        },

        methods: {
            initValues() {

            },
            mapInitialized: function (map) {
                this.themap = map

                this.marker = new mapboxgl.Marker({
                    draggable: true
                })
                    .setLngLat([this.project.long, this.project.lat])
                    .addTo(this.themap);

                this.marker.on('dragend', this.dragged)

                this.themap.jumpTo({center: [this.project.long, this.project.lat], zoom: 16 });
            },

            dragged(marker) {
                let lngLat = marker.target._lngLat
                this.package.long = lngLat.lng
                this.package.lat = lngLat.lat
            },

            updateLocation() {
                this.marker.setLngLat([this.package.long, this.package.lat])
                    .addTo(this.themap);
            },

            submit() {
                this.$store.dispatch('packages/updatePackage', { package_id: this.package.id, formData: {project_id: this.project.id, long: this.package.long, lat: this.package.lat }})
                    .then(response => {
                        this.$parent.close()
                        this.$router.push({ name:'package.detail', params: {id: this.package.id}})
                        this.$buefy.toast.open({
                            message: 'Package attached',
                            type: 'is-success'
                        })

                    }).catch(err => {
                    this.$buefy.toast.open({
                        message: err,
                        type: 'is-danger'
                    })
                })
            }

        }

    }
</script>

<style scoped>

</style>