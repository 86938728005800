<template>
  <div class="tile is-ancestor is-vertical">

    <div class="tile is-parent">

    <card-component class="tile is-child has-table" title="Towers" icon="eye" :has-button-slot="true">
        <div slot="button" class="buttons">
            <b-button v-can="`manage all packages`" @click="formModal(null)" type="is-grey" size="is-small" icon-left="plus-circle">
                Create
            </b-button>
<!--            <refresh-button slot="button" :isLoading="loading" @button-click="fetchData"/>-->
        </div>
        <b-table
                :data="packages"
                hoverable
                v-on:click="goToDetail"
                style="cursor: pointer"
        >

            <b-table-column searchable cell-class="vcenterplease" field="name" label="Name" sortable>
              <template #searchable="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Search..."
                    icon="magnify"
                    size="is-small" />
              </template>
              <template v-slot="props">
                {{ props.row.name }}
              </template>
            </b-table-column>
            <b-table-column searchable cell-class="vcenterplease" field="project.name" label="Project" sortable>
              <template #searchable="props">
                <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Search..."
                    icon="magnify"
                    size="is-small" />
              </template>
              <template v-slot="props">
                <b-tag v-if="props.row.project"> {{ props.row.project.name }} </b-tag>
              </template>
            </b-table-column>

            <b-table-column cell-class="vcenterplease" field="runner_version" label="Runner" v-slot="props" sortable>
              <b-tag v-for="version in props.row.runner_version" :key="props.row.id"> {{ version }} </b-tag>
            </b-table-column>

            <b-table-column cell-class="vcenterplease" field="status.alarm_status" label="Status" centered v-slot="props" sortable>
              <StatusLineComponent :status="props.row.status" :expanded="false"></StatusLineComponent>
            </b-table-column>

            <b-table-column cell-class="vcenterplease" custom-key="actions" class="is-actions-cell" v-slot="props">
                <div v-can="'manage all packages'" class="buttons is-right">
<!--                    <b-button icon-left="eye" size="is-small" type="is-primary" @click.native="$router.push({ name:'package.detail', params: {id: props.row.id}})"></b-button>-->
                    <b-button icon-left="pencil" size="is-small" type="is-success" @click.native.stop="$router.push({ name:'package.edit', params: {id: props.row.id}})"></b-button>
                    <b-button icon-left="delete" size="is-small" type="is-danger" @click.native.stop="deleteModal(props.row.id)"></b-button>

                </div>
            </b-table-column>

            <template slot="empty">
                <br>
                <p class="has-text-centered">
                    <b-icon icon="cactus" size="is-large" />
                </p>
                <p class="has-text-centered">No Towers/Sets found!</p>
                <br>
            </template>
        </b-table>

    </card-component>
    </div>
  </div>


</template>

<script>
    import ProjectFormComponent from "../project/ProjectFormComponent";
    import PackageFormComponent from "./PackageFormComponent";
    import CardComponent from '@/components/CardComponent'
    import RefreshButton from '@/components/RefreshButton'
    import packages from "@/store/modules/packages.store"
    import StatusLineComponent from "@/components/StatusLineComponent";
    const GeoJSON = require('geojson');


    export default {
        name: "PackageTableComponent",
        components: {StatusLineComponent, CardComponent, RefreshButton },
        props: {
          active_only: {
            type: Boolean,
            default: false
          },
        },

        data() {
            return {
                loading: true,
                isEmpty: false,

            }
        },

        computed: {
            packages() {
                return this.$store.state.packages.all_packages
            }

        },

        created() {
            if(!packages.isRegistered) {
                this.$store.registerModule('packages', packages)
                packages.isRegistered = true
            }
        },
        mounted() {
            this.fetchData()
        },

        methods: {

            fetchData() {
                this.loading  = true;
                this.$store.dispatch('mapbox/setHeader', {
                  stack: null,
                  status: null
                })
                const params = [
                  'include=project',
                  //`sort=${this.sort_order === "asc" ? "" : "-"}${this.sort_field}`,
                  `filter[active]=${!this.active_only}`,
                ].join('&')


                this.$store.dispatch('packages/fetchPackages', params)
                    .then(response => {
                        if(response.data.data.length > 0) {
                            const geo = GeoJSON.parse(response.data.data, {Point: ['lat', 'long']});
                            this.$store.dispatch('mapbox/drawPackages', { ctx: this, geo: geo, packages: response.data.data, snap: true} )
                        }

                        this.loading = false
                    })
            },

            goToDetail(row) {
                this.$router.push({ name:'package.detail', params: { project_id: row.project.id, id: row.id}})
            },

          onSort(field, order) {
            this.sort_field = field
            this.sort_order = order
            this.fetchData()
          },


            formModal(id) {
                this.$buefy.modal.open({
                    parent: this,
                    component: PackageFormComponent,
                    props: {
                        id: id
                    },
                    hasModalCard: true,
                    customClass: 'custom-class custom-class-2',
                    trapFocus: true
                })
            },

            deleteModal(id) {
                this.$buefy.dialog.confirm({
                    title: 'Delete Package',
                    message: 'Are you sure you want to <b>delete</b> this package? This action cannot be undone. All linked products ' +
                        'will be unlinked.',
                    confirmText: 'Delete Package',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('packages/deletePackage', id)
                            .then(response => {
                                this.$buefy.toast.open('Package deleted')
                            })
                    }
                })
            },

        }

    }
</script>

<style lang="scss" scoped>

    .package-card-content {
        padding: 1rem ;

    }

    .b-table {
        .table {
            td.vcenterplease {
                vertical-align: middle;
            }
        }
    }

    .card__one {
        transition: transform .2s;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: opacity 1s cubic-bezier(.165, .84, .44, 0.5);
            box-shadow: 0 5px 12px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .15);
            content: '';
            opacity: 0;
        }

        &:hover,
        &:focus {
            transform: scale3d(1.006, 1.006, 1);

            &::after {
                opacity: 1;
            }
        }
    }
</style>