<template>
  <div class="tile is-ancestor is-vertical">

    <div class="tile is-parent">

    <card-component class="tile is-child has-table has-mobile-sort-spaced" :has-button-slot="true" title="Projects" icon="map-marker">
            <div slot="button" class="buttons">
                <b-button v-can="'manage all projects'" @click="formModal(null)" type="is-grey" size="is-small" icon-left="plus-circle">
                    New Project
                </b-button>
    <!--            <refresh-button slot="button" :isLoading="loading" @button-click="fetchData"/>-->
            </div>
            <b-table
                :data="projects"
                :hoverable="true"
                :show-header="true"
                v-on:click="goToProject"
                style="cursor: pointer"
                >

                <b-table-column cell-class="vcenterplease" field="icon" width="40"  v-slot="props">
                    <eye-icon type="project" :id="props.row.id" :status="props.row.status"></eye-icon>
                </b-table-column>
                <b-table-column searchable cell-class="vcenterplease" field="name" label="Project">
                  <template #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Search..."
                        icon="magnify"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.name }}
                  </template>
                </b-table-column>

                <b-table-column searchable cell-class="vcenterplease" field="company.name" label="Company">
                  <template #searchable="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        placeholder="Search..."
                        icon="magnify"
                        size="is-small" />
                  </template>
                  <template v-slot="props">
                    <b-tag>{{ props.row.company.name }}</b-tag>
                  </template>
                </b-table-column>

                <b-table-column cell-class="vcenterplease" field="status" label="Status" centered v-slot="props">
                  <StatusLineComponent :status="props.row.status" :expanded="false"></StatusLineComponent>
                </b-table-column>

                <b-table-column cell-class="vcenterplease" custom-key="actions" class="is-actions-cell" v-slot="props">
                  <div class="buttons is-right">
                    <b-button v-can="`${props.row.id}.manage project`"
                              icon-left="pencil"
                              size="is-small"
                              type="is-success"
                              @click.native.stop="$router.push({ name:'project.edit', params: {id: props.row.id}})">
                    </b-button>
                  </div>
                </b-table-column>

                <template slot="empty">
                    <br>
                    <p class="has-text-centered">
                        <b-icon icon="cactus" size="is-large" />
                    </p>
                    <p class="has-text-centered">No projects found!</p>
                    <br>
                </template>
            </b-table>
        </card-component>

    </div>
  </div>
</template>

<script>
    import projects from "@/store/modules/projects.store"
    import CardComponent from "@/components/CardComponent";
    import RefreshButton from '@/components/RefreshButton'
    import EyeIcon from "../EyeIcon";
    import StatusLineComponent from "@/components/StatusLineComponent";
    import ProjectFormComponent from "@/components/project/ProjectFormComponent";
    const GeoJSON = require('geojson');

    export default {
        name: "ProjectTableComponent",
        components: {StatusLineComponent, EyeIcon, CardComponent, RefreshButton },

        data () {
            return {
                data: [],
                loading: true,
                total: 0,
                page: 1,
                per_page: 10,
                sort_field: "",
                sort_order: "asc",
            }
        },

        created() {
            if(!projects.isRegistered) {
                this.$store.registerModule('projects', projects)
                projects.isRegistered = true
            }
        },

        beforeMount() {
            this.fetchData()
        },

        computed: {

            projects() {
                return this.$store.state.projects.all_projects
            }
        },

        methods: {

            fetchData() {
                this.loading = true
                this.$store.dispatch('mapbox/setHeader', {
                  stack: null,
                  status: null
                })

                this.$store.dispatch('projects/fetchProjects')
                    .then(response => {
                        if(this.$store.state.projects.all_projects.length > 0) {
                            const geo = GeoJSON.parse(this.$store.state.projects.all_projects, {Point: ['lat', 'long']});
                            this.$store.dispatch('mapbox/drawProjects', { ctx: this, geo: geo, projects: this.$store.state.projects.all_projects} )
                        }
                        this.loading = false
                    })
            },

            goToProject(row){
                this.$router.push({ name:'project.detail', params: {id: row.id}})
            },


            formModal(id) {
              this.$buefy.modal.open({
                parent: this,
                component: ProjectFormComponent,
                props: {
                  id: id
                },
                hasModalCard: true,
                trapFocus: false,
                ariaRole: "dialog",
                ariaModal: true,
                scroll: "keep"
              })
            },

            deleteModal(id) {
                this.$buefy.dialog.confirm({
                    title: 'Removing Project',
                    message: 'Are you sure you want to <b>remove</b> this project? This action cannot be undone.',
                    confirmText: 'Remove Camera',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.deleteProject(id)
                    }
                })
            },
            deleteProject(id) {
                this.$store.dispatch('projects/deleteProject', id)
                    .then(response => {
                        this.$buefy.toast.open('Project deleted')
                        this.$router.push({ name:'projects'})

                    })
            },


        }

    }
</script>

<style lang="scss">

    .b-table {
        .table {
            td.vcenterplease {
                vertical-align: middle;
            }
        }
    }

</style>