<template>
  <card-component title="Recordings for alert" icon="eye" :is-scrollable="true" :has-footer-slot="true">

    <div class="content" style="width: 100%">
      <b-notification type="is-info" aria-close-label="Close notification">
        <b>Click on the thumbnail to view the recording.</b>
        If the recording hasn't been sent to the server yet, it might take a while to load.
      </b-notification>

      <div >
       <recording-player :source="src">
       </recording-player>
      </div>


    </div>

    <div class="media-list">
      <b-loading :is-full-page="false" :active="isLoading"/>
      <recording-item v-for="recording in alert.recordings" :key="recording.id" :recording="recording"
                      v-on:update="fetchData" v-on:play="setSource"/>
    </div>

      <a v-can="'verify all alerts'" class="card-footer-item" slot="footer" @click.prevent="denyAlert()">
        <b-icon type="is-success" icon="check" custom-size="default"/>
        <span class="has-text-success">mark as false</span>
      </a>
      <a v-can="'verify all alerts'" class="card-footer-item" slot="footer" @click.prevent="approveAlert()">
        <b-icon type="is-danger" icon="close" custom-size="default"/>
        <span class="has-text-danger">mark as alarm</span>
      </a>

  </card-component>
</template>

<script>
import devices from "@/store/modules/devices.store"
import CardComponent from "../CardComponent";
import RecordingItem from "./RecordingItem";
import RecordingPlayer from "@/components/package/RecordingPlayer.vue";

export default {
  name: "AlertDetailComponent",
  components: {RecordingPlayer, CardComponent, RecordingItem},
  props: {
    project_id: null,
    alert_id: null
  },
  data() {
    return {
      alert: {},
      src: null,
      isLoading: true,
      isPlaying: false,

    }
  },

  computed: {
    creating() {
      return this.id === null
    },


  },
  created() {
    if (!devices.isRegistered) {
      this.$store.registerModule('devices', devices)
      devices.isRegistered = true
    }
    this.$store.dispatch('mapbox/setStack', null)
    this.fetchData()
  },



  methods: {

    fetchData() {
      //Fetch nas data
      let payload = {
        'project_id': this.project_id,
        'alert_id': this.alert_id
      }

      this.$store.dispatch('packages/fetchAlertDetails', payload)
          .then(response => {
            this.alert = JSON.parse(JSON.stringify(response.data.data))
            this.isLoading = false
            //this.$refs.videoPlayer.player.poster(`data:image/png;base64,${this.alert.recordings[0].snapshot}`)

          })
    },
    setSource(nsrc) {
      this.src = nsrc;
      this.isPlaying = true
    },

    approveAlert() {
      this.$store.dispatch('packages/updateAlert', {
        project_id: this.project_id,
        alert_id: this.alert_id,
        formData: {status: 'relevant'}
      })
          .then(response => {

            alert.status = 'relevant'
            this.$emit("refresh")
            this.$buefy.snackbar.open({
              message: 'Marked as relevant',
              queue: false
            })
          })
      this.$buefy.toast.open({
        message: 'Marked as relevant',
        queue: false
      })
    },
    denyAlert() {
      this.$store.dispatch('packages/updateAlert', {
        project_id: this.project_id,
        alert_id: this.alert_id,
        formData: {status: 'irrelevant'}
      })
          .then(response => {
            this.$emit("refresh")
            alert.status = 'irrelevant'
            this.$buefy.toast.open({
              message: 'Marked as irrelevant',
              queue: false
            })
          })

    }


  }

}
</script>

<style>


.vjs-poster {
  background-size: 100% !important;
}


</style>
