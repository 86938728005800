/* devices.store.js */

// State object
const state = {
    all_nas: [],
    unused_nas: [],
    all_cameras: [],
    all_alarms: [],
    all_aiboxes: [],

}

// Getter functions
const getters = {
    getNasByPackageId: (state) => (id) => {
        return state.all_nas.filter(nas => {
                return nas.package_id === id

        })
    },
    getAlarmsByPackageId: (state) => (id) => {
        return state.all_alarms.filter(alarm => {
            return alarm.package_id === id

        })
    },
    getCamerasByPackageId: (state) => (id) => {
        return state.all_alarms.filter(alarm => {
            return alarm.package_id === id

        })
    },
    unusedNas: (state) => {
        return state.unused_nas
    },
    unusedAlarms: (state) => {
        return state.all_alarms.filter(nas => {
            return !nas.package
        })
    }


}

// Actions
const actions = {
    fetchNas({commit}, params) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/nas?${params}`)
                .then((response) => {
                    commit('SET_NAS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchUnusedNas({commit}, params) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/nas?${params}`)
                .then((response) => {
                    commit('SET_UNUSED_NAS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchCameras({commit}, params) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/cameras?${params}` )
                .then((response) => {
                    commit('SET_CAMERAS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchAlarms({commit}, params) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/alarms?${params}`)
                .then((response) => {
                    commit('SET_ALARMS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchNasDetails({commit}, id) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/nas/${id}`)
                .then((response) => {
                    //commit('UPDATE_OR_SET_MASTS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchCameraDetails({commit}, id) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/cameras/${id}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchAlarmDetails({commit}, id) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/alarms/${id}?include=riscorunner`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchAIBoxDetails({commit}, id) {
      return new Promise((resolve, reject) => {
        this.$axios.get(`/api/aiboxes/${id}?include=worker`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createNas({commit}, formData) {
        return new Promise((resolve, reject) => {
            this.$axios.post("/api/nas", formData)
                .then((response) => {
                    commit('UPDATE_OR_ADD_NAS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    createCamera({commit}, formData) {
        return new Promise((resolve, reject) => {
            this.$axios.post("/api/cameras", formData)
                .then((response) => {
                    commit('UPDATE_OR_ADD_CAMERA', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    createAlarm({commit}, formData) {
        return new Promise((resolve, reject) => {
            this.$axios.post("/api/alarms", formData)
                .then((response) => {
                    commit('UPDATE_OR_ADD_ALARM', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    updateNas({commit}, formData) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/nas/${formData.id}`, formData)
                .then((response) => {
                    let result = response.data.data
                    if(formData.package_id === null) {
                        result.package = null
                    }
                    commit('UPDATE_OR_ADD_NAS', result)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    rekeyNas({commit}, nas) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/nas/${nas.id}/rekey`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    updateAlarm({commit}, formData) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/alarms/${formData.id}`, formData)
                .then((response) => {
                    let result = response.data.data

                    if(formData.package_id === null) {
                        result.package = null
                        result.nas = null
                    }
                    commit('UPDATE_OR_ADD_ALARM', result)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    updateAIBox({commit}, formData) {
      return new Promise((resolve, reject) => {
        this.$axios.patch(`/api/aiboxes/${formData.id}`, formData)
          .then((response) => {
            let result = response.data.data

            if(formData.package_id === null) {
              result.package = null
            }
            commit('UPDATE_OR_ADD_AIBOX', result)
            resolve(response)
          })
          .catch((error) => {
            reject(error.response.data.message)
          })
      })
    },
    updateCamera({commit}, formData) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/cameras/${formData.id}`, formData)
                .then((response) => {
                    commit('UPDATE_OR_ADD_CAMERA', response.data.data)

                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    deleteNas({commit}, id) {
        return new Promise((resolve, reject) => {
            this.$axios.delete(`/api/nas/${id}`)
                .then((response) => {
                    commit('DELETE_NAS', id)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    deleteCamera({commit}, id) {
        return new Promise((resolve, reject) => {
            this.$axios.delete(`/api/cameras/${id}`)
                .then((response) => {
                    commit('DELETE_CAMERA', id)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    deleteAlarm({commit}, id) {
        return new Promise((resolve, reject) => {
            this.$axios.delete(`/api/alarms/${id}`)
                .then((response) => {
                    commit('DELETE_ALARM', id)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    syncCameras({commit}, id){
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/nas/${id}`, { sync: true})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    syncCameraStream({commit}, id){
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/cameras/${id}/syncstream`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    disconnectAlarm({commit}, id){
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/alarms/${id}/disconnect`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    connectAlarm({commit}, id){
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/alarms/${id}/connect`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    rebootAlarm({commit}, id){
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/alarms/${id}/reboot`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    syncAlarm({commit}, id) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/alarms/${id}/sync`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    syncAIBox({commit}, id) {
      return new Promise((resolve, reject) => {
        this.$axios.patch(`/api/aiboxes/${id}/sync`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
}

// Mutations
const mutations = {
    SET_NAS(state, payload) {
      state.all_nas = payload
    },
    SET_UNUSED_NAS(state, payload) {
        state.unused_nas = payload
    },
    UPDATE_OR_ADD_NAS(state, current_nas) {
        let item = state.all_nas.find(nas => nas.id === current_nas.id)
        if(item) {
            Object.assign(item, current_nas)
        }
        else {
            state.all_nas.unshift(current_nas) // Add nas to all_nas
        }
    },
    DELETE_NAS(state, id) {
        let index = state.all_nas.findIndex(nas => nas.id === id)
        state.all_nas.splice(index, 1)

    },
    SET_ALARMS(state, payload) {
        state.all_alarms = payload
    },
    UPDATE_OR_ADD_ALARM(state, current_alarm) {
        let item = state.all_alarms.find(alarm => alarm.id === current_alarm.id)
        if(item) {
            Object.assign(item, current_alarm)
        }
        else {
            state.all_alarms.unshift(current_alarm) // Add alarm to all_alarms
        }
    },
    UPDATE_OR_ADD_AIBOX(state, current_aibox) {
      let item = state.all_aiboxes.find(box => box.id === current_aibox.id)
      if(item) {
        Object.assign(item, current_aibox)
      }
      else {
        state.all_aiboxes.unshift(current_aibox) // Add alarm to all_alarms
      }
    },
    DELETE_ALARM(state, id) {
        let index = state.all_alarms.findIndex(alarm => alarm.id === id)
        state.all_alarms.splice(index, 1)
    },
    SET_CAMERAS(state, payload) {
        state.all_cameras = payload
    },
    UPDATE_OR_ADD_CAMERA(state, current_camera) {
        let item = state.all_cameras.find(camera => camera.id === current_camera.id)
        if(item) {
            Object.assign(item, current_camera)
        }
        else {
            state.all_cameras.unshift(current_camera) // Add camera to all_cameras
        }
    },
    DELETE_CAMERA(state, id) {
        let index = state.all_cameras.findIndex(camera => camera.id === id)
        state.all_cameras.splice(index, 1)

    },


}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
