<template>
    <card-component :title="this.creating ? 'Create Package' : 'Edit Package'"
                    :icon="this.creating ? 'plus-circle' : 'pencil'"
                    :is-scrollable="true">
        <form @submit.prevent="submit">
            <b-field label="Package name" horizontal>
                <b-field>
                    <b-input v-model="package.name" placeholder="Name" name="name" required />
                </b-field>
            </b-field>

            <hr>

<!--            <b-field label="Location" horizontal>-->
<!--                <b-field>-->
<!--                    <b-input v-model="package.long" placeholder="Longitude" name="long" required @input="updateLocation"/>-->
<!--                </b-field>-->
<!--                <b-field>-->
<!--                    <b-input v-model="package.lat" placeholder="Latitude" name="lat" required @input="updateLocation" />-->
<!--                </b-field>-->
<!--            </b-field>-->
<!--            <b-field label="Site" horizontal>-->
<!--                <b-field>-->
<!--                    <b-input v-model="package.site_id" placeholder="1" name="site_id" required />-->
<!--                </b-field>-->
<!--            </b-field>-->
            <b-field label="Keycode" horizontal>
                <b-field>
                    <b-input v-model="package.keycode" placeholder="e.g. 1234" name="keycode" required />
                </b-field>
            </b-field>

            <hr>

            <b-field label="Battery Included" horizontal>
                <b-switch v-model="package.has_battery">
                </b-switch>
            </b-field>

            <b-field horizontal >
                <b-field grouped position="is-right">
                    <div class="control">
                        <b-button type="is-primary is-outlined" @click="initValues">Reset</b-button>
                    </div>
                    <div class="control">
                        <b-button native-type="submit" type="is-primary">{{ this.creating ? "Create" : "Update" }}</b-button>
                    </div>
                </b-field>
            </b-field>

        </form>
    </card-component>
</template>

<script>
    import packages from "@/store/modules/packages.store"
    import CardComponent from "../CardComponent";

    import Mapbox from 'mapbox-gl-vue'
    import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

    export default {
        name: "PackageFormComponent",
        components: { CardComponent, Mapbox },
        props: {
            id: {
                default: null
            },
        },


        data() {
            return {
                package: {
                    name: "",
                    site_id: null,
                    keycode: "",
                    long: 4.8640,
                    lat: 51.2770,
                    has_battery: false,
                },


            }
        },

        computed: {
            creating () {
                return this.id === null
            }

        },
        created() {
            if(!packages.isRegistered) {
                this.$store.registerModule('packages', packages)
                packages.isRegistered = true
            }
        },

        mounted() {
            if (this.id === null) {
                this.initValues()
            }
            else {
                // Fetch package data
                this.$store.dispatch('packages/fetchPackageDetails', this.id)
                    .then(response => {
                        this.package = JSON.parse(JSON.stringify(response.data.data))
                    })

            }
        },

        methods: {
            initValues() {

            },

            submit() {
                if (this.creating) {
                    this.$store.dispatch('packages/createPackage', this.package)
                        .then(response => {
                            this.$parent.close()
                            this.$router.push({ name:'package.edit', params: {id: response.data.data.id}})
                            this.$buefy.toast.open({
                                message: 'Package created',
                                type: 'is-success'
                            })

                        }).catch(err => {
                        this.$buefy.toast.open({
                            message: err,
                            type: 'is-danger'
                        })
                        })
                }

            }
        }

    }
</script>

<style scoped>

</style>