<template>
  <div  class="media">
    <figure class=" media-left is-2by1">

      <figure class="image image-container" style="cursor: pointer" @click="stream">
        <b-loading v-model="loading" :is-full-page="false"></b-loading>

        <img class="theimage" width="640" height="360" v-if="recording.snapshot"
             :src="`data:image/png;base64,${recording.snapshot}`"
        >
        <div class="overlay">
          <b-icon type="is-white" size="is-large" icon="play-box"/>
        </div>
      </figure>

    </figure>
    <div class="media-content">
      <div class="content">

      </div>
      <nav class="level is-mobile">
        <div class="level-left ">
            <div class="block level-item">
                <b-icon size="is-small" type="is-grey" icon="clock"></b-icon>
            </div>
          <div class="block level-item">
            {{  recording.starttime | moment("HH:mm:ss") }}
          </div>
          <div class="block level-item">
            <b-icon size="is-small" type="is-grey" icon="arrow-right"></b-icon>
          </div>
          <div class="block level-item">
            {{  recording.endtime | moment("HH:mm:ss") }}
          </div>
        </div>

      </nav>
      <nav class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <b-icon :type="recording.downloaded_at ? 'is-success' : 'is-grey-dark'"
                    :icon="recording.downloaded_at ? 'check-circle' : 'alert-circle-outline'"
                    size="is-small">
            </b-icon>
          </div>
          <div class="level-item">
            <span class="has-text-grey-dark is-size-7">{{ recording.downloaded_at ? 'downloaded at ' + recording.downloaded_at  : 'not downloaded yet'}}</span>
          </div>
          <div class="block level-item">
            <b-button :loading="loading" size="is-small" type="is-pimary" icon-left="download" @click="download"></b-button>
          </div>

        </div>

      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecordingItem',
  props: {
    recording: {
      type: Object,
      default: () => {}
    },

  },
  data () {

    return {

      loading: false,
    }
  },
  computed: {
    duration() {

    }

  },

  methods: {

    statusLayout(status){
      if(status === "relevant"){
        return {
          type: 'is-success',
          msg: 'Relevant'
        }
      } else if(status === "irrelevant"){
        return {
          type: 'is-grey',
          msg: 'Irrelevant'
        }
      } else if(status === "pending"){
        return {
          type: 'is-warning',
          msg: 'To validate'
        }
      }
    },

    download () {
      this.loading = true
      this.$store.dispatch('packages/downloadRecording', this.recording.id )
        .then(response => {
          this.$buefy.toast.open({
            message: 'Downloaded',
            queue: false
          })
          this.loading = false
        })
      .catch(error => {
        this.$buefy.toast.open({
          type: "is-danger",
          message: 'Recording could not be downloaded',
          queue: false
        })
        this.loading = false
      })
    },

    stream () {
      this.loading = true
      this.$store.dispatch('packages/streamRecording', this.recording.id )
        .then(response => {
          this.$emit('update')
          this.$emit('play', response.data.url)
          this.loading = false
        })
          .catch(error => {
            this.$buefy.toast.open({
              type: "is-danger",
              message: 'Recording could not be streamed',
              queue: false
            })
            this.loading = false
          })
    }

  },

}
</script>

<style >
  /* Container needed to position the overlay. Adjust the width as needed */
  .image {
    position: relative;

  }

  /* Make the image to responsive */
  .theimage {
    display: block;
    width: 100%;
    height: auto;
  }

  /* The overlay effect - lays on top of the container and over the image */
  .overlay {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    transition: .5s ease;
    opacity:0.4;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
  }

  /* When you mouse over the container, fade in the overlay title */
  .image:hover .overlay {
    opacity: 1;
  }
</style>
